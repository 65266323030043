.form-element {
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-bottom: 32px;

   &.form-element--is-valid {
      .react-select__control,
      input {
         border-color: #62c8be !important;
      }
   }

   .label {
      font-family: "BrownPro-Bold";
      margin-bottom: 10px;
   }
}

button {
   padding: 11px 16px;
   font-family: "BrownPro-Regular";
   color: #ffffff;
   border-radius: 5px;
   background-color: #323232;
   border: 0;
   font-size: 15px;
   outline: none;

   &:enabled {
      cursor: pointer;
   }

   &:hover:enabled {
      background-color: #222222;
   }

   &:active:enabled {
      transform: scale(0.97);
   }

   &:focus {
      box-shadow: 0 0 0 3px rgb(50 50 50 / 50%)
   }

   &[disabled] {
      opacity: 0.5;
   }
}

input[type="number"] {
   border: 1px solid #5a5a5a;
   border-radius: 5px;
   padding: 10px;
   font-family: "BrownPro-Regular";
   font-size: 16px;
   outline: none !important;

   &:focus {
      box-shadow: 0 0 0 3px #62c8be52;
   }
}
