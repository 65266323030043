.calculator {
   .header {
      background-color: #e9fcfa;
      font-family: 'BrownPro-Bold';
      color: #000000;
      display: flex;
      height: 70px;
      font-weight: 700;
      padding: 0 25px;
   
      &::before {
         content: "";
         background: url("../../assets/gfx/logo-arbeidstilsynet.svg") no-repeat center;
         background-size: 34px;
         width: 34px;
         margin: -10px 2px 0 0;
      }
   
      .title {
         display: flex;
         margin-bottom: -10px;
   
         span {
            display: flex;
            align-items: center;
         }
   
         span:first-child::after {
            content: "";
            border-right: 2px solid #000000;
            height: 30px;
            margin: 0 20px;
         }
      }
   }
   
   .form {
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      padding: 25px 25px 30px 25px;
   
      button {
         margin-bottom: 80px;
      }
   
      .calculate {
         margin-top: 18px;
         align-self: center;
      }
   
      .result {
         display: flex;
         flex-direction: column;
         font-family: 'BrownPro-Bold';
         
         > div {
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
   
            span:nth-child(1) {
               width: 30%;
            }

            span:nth-child(2) {
               width: 70%;
               text-align: right;
            }

            &:last-child {
               margin-bottom: 0;
            }
         }
      }
   }
}
