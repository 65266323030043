@import '~react-pure-modal/dist/react-pure-modal.min.css';
@import './styles/_fonts.scss';
@import './styles/_form.scss';
@import './styles/_overrides.scss';

body {
   margin: 0;
   color: rgba(0, 0, 0, 0.85);
   font-family: 'BrownPro Regular', sans-serif;
   font-weight: 400;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

